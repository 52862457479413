import { Button, Center, Flex, Loader, Radio, Stack, TextInput } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { GetSensorResponse } from 'api/actions/get-sensor/get-sensor-response';
import useLang from 'lang';
import panic from 'panic';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MinusIcon } from 'components/icons/MinusIcon';
import { PlusIcon } from 'components/icons/PlusIcon';
import { useApi } from 'api/api-context';
import P1Medium from 'theme/components/Typography/P1Medium';
import H5SemiBold from 'theme/components/Typography/H5SemiBold';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';

type Sensor = GetSensorResponse['sensor'];

/**
 *
 * @returns The component tree for the alert settings page.
 */
export default function AlertSettingsPage() {
  const { _t } = useLang();
  const { getAction } = useApi();
  const [sensor, setSensor] = useState<Sensor | null>(null);
  const { sensorId: sensorIdRaw = '' } = useParams();
  const sensorId = useMemo(() => parseInt(sensorIdRaw), [sensorIdRaw]);

  useEffect(() => {
    if (sensorId) {
      const getSensor = getAction('GetSensor');

      getSensor({ parameters: { sensorId } })
        .then(({ sensor }) => setSensor(sensor))
        .catch(panic);
    }
  }, [sensorId]);

  if (!sensor) {
    return (
      <Center style={{ height: '100vh' }}>
        <Flex direction="column" align="center" gap="1rem">
          <Loader size="lg" color="#551FFF" />
          <H2SemiBold>{_t('Načítavanie nastavení')}</H2SemiBold>
        </Flex>
      </Center>
    );
  }

  return (
    <Stack bg="gray.0" py={20} px={16} h="100vh">
      <Flex justify="space-between" align="center" mb={6}>
        <span></span>
        <span style={{ textAlign: 'center', width: '100%' }}>
          <H5SemiBold>Nastavenia upozornení</H5SemiBold>
        </span>
        <span style={{ height: '24px', width: '24px' }}></span>
      </Flex>
      <Flex direction="column" justify={'space-between'} h="100%">
        {sensor?.alerts?.map((alert) => (
          <div>
            {alert.options?.map((option) => (
              <Stack bg="white" p={16} key={alert.title} style={{ borderRadius: '0.625rem' }} mb={20}>
                <Radio.Group onChange={() => {}}>
                  <Flex direction={'column'} mt="xs" w="100%">
                    <div>
                      {option.type === 'select' ? (
                        <>
                          <P1Medium pb={16}>{option.label}</P1Medium>
                          {option.options?.map((selectOption) => (
                            <Radio
                              size="md"
                              py={16}
                              label={selectOption.label}
                              key={selectOption.value}
                              value={selectOption.value}
                              styles={{ root: { borderTop: '1px solid #E0E4EC' } }}
                              color="primary.0"
                            />
                          ))}
                        </>
                      ) : null}
                    </div>
                    <div>
                      {option.type === 'number' ? (
                        <>
                          <P1Medium pb={16}>{option.label}</P1Medium>
                          <Flex py={16} gap={16} justify="space-between" align="center">
                            <span onClick={() => (option.value ?? 0) - 1} style={{ cursor: 'pointer' }}>
                              <MinusIcon />
                            </span>
                            <TextInput
                              variant="tertiary"
                              radius={10}
                              styles={{
                                input: {
                                  border: '1px solid #E0E4EC',
                                  height: '48px',
                                  textAlign: 'center',
                                  fontSize: '18px',
                                },
                              }}
                              value={`${option.value ?? 0} ${option.suffix}`}
                              readOnly
                              placeholder={`${option.value ?? 0} ${option.suffix}`}
                            />
                            <span onClick={() => (option.value ?? 0) + 1} style={{ cursor: 'pointer' }}>
                              <PlusIcon />
                            </span>
                          </Flex>
                        </>
                      ) : null}
                    </div>
                  </Flex>
                </Radio.Group>
              </Stack>
            ))}
          </div>
        ))}
        <Button variant="primary" size="md" leftSection={<IconCheck size={24} />}>
          Uložiť
        </Button>
      </Flex>
    </Stack>
  );
}
