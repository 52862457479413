import { BackgroundImage, Box, Divider, Flex } from '@mantine/core';
import React, { useMemo } from 'react';
import theme from 'theme';
import useLang from 'lang';
import SimpleLayout from 'layouts/SimpleLayout';
import H1SemiBold from 'theme/components/Typography/H1SemiBold';
import P1Regular from 'theme/components/Typography/P1Regular';
import Menu from 'components/Menu';
import P1Medium from 'theme/components/Typography/P1Medium';
import { Link, useSearchParams } from 'react-router-dom';
import P2Medium from 'theme/components/Typography/P2Medium';
import P2Regular from 'theme/components/Typography/P2Regular';
import { FooterIcon } from 'components/icons/FooterIcon';
import { SensorDetailImpl } from 'pages/sensors/SensorDetail';

/**
 * Dashboard page
 */
const Dashboard: React.FC = () => {
  const { _t } = useLang();
  const [searchParams] = useSearchParams();

  const type = useMemo(() => searchParams.get('t'), [searchParams]);
  const id = useMemo(() => searchParams.get('d'), [searchParams]);

  if (type === 'sensor' && id) {
    return <SensorDetailImpl sensorId={Number(id)} />;
  }

  return (
    <>
      <SimpleLayout>
        <BackgroundImage
          src="img/homescreen.jpeg"
          style={{
            backgroundPosition: 'center',
          }}
        >
          <Flex direction="column" align="center" justify="center" h="12.5rem" style={{ background: '#00000080' }}>
            <H1SemiBold color={theme.white}>{_t('Your smart centre')}</H1SemiBold>
            <P1Regular color={theme.white}>{_t('Powered by Phillip')}</P1Regular>
          </Flex>
        </BackgroundImage>
        <Box py="md" style={{ position: 'relative', top: '-3.125rem' }}>
          <Menu />
        </Box>
        <Box bg={theme.colors?.primary?.[10]} py="2.125rem" px="1rem">
          <Flex direction="row" justify="space-between" align="center" py="1rem">
            <Box>
              <FooterIcon />
            </Box>
            <Box style={{ color: theme.white }} w="10.125rem">
              <P1Medium>{_t('Smart solution for residential projects')}</P1Medium>
            </Box>
          </Flex>
          <Divider color={theme.colors?.primary?.[8]} />
          <Box py="1rem">
            <Link style={{ color: theme.white }} to={''}>
              <P2Medium>{_t('info@phillip.city')}</P2Medium>
            </Link>
          </Box>
          <Divider color={theme.colors?.primary?.[8]} />
          <Box py="1rem">
            <Link style={{ color: theme.white, textDecoration: 'none' }} to={''}>
              <P2Medium>{_t('+421 908 022 882')}</P2Medium>
            </Link>
          </Box>
          <Divider color={theme.colors?.primary?.[8]} />
          <Box py="0.75rem" ta="center">
            <Link style={{ color: theme.colors?.primary?.[4], textDecoration: 'none' }} to={''}>
              <P2Regular>{_t('© Copyright Phillip')}</P2Regular>
            </Link>
          </Box>
        </Box>
      </SimpleLayout>
    </>
  );
};

export default Dashboard;
